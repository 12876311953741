<template>
        <div class="image-container">
            <img src="images/warning2-removebg-preview.png" height="300" class="image" />
            <img src="images/cloud2.png" height="200" class="image" />
            <img src="images/thunder2ok.png" height="130" class="image" />
        </div>
</template>
  
<script>

export default {

    data() {
        return {

        };
    },
};
</script>

<style>

.image-container {
  position: relative;
  width: 100vw; 
  height: 100vh; 
}

.image {
    position: absolute;
    margin: auto;
}

.image:nth-child(1) {
  top: 0;
  left: 50%;
}


/* .warning {
    top: 2%;
    left: 9%;
}

.cloud {
    top: 10vw;
    left: 9%;
    z-index: 2;
}

.thunder {
    top: 29%;
    left: 43.5%;
    z-index: 1;
    animation: moveUpDown 5s ease infinite;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(-10px); 
  }
  50% {
    transform: translateY(12px); 
  }
} */

</style>